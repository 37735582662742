import { graphql, Link, useStaticQuery } from 'gatsby';

import { Hero } from '../components/Layout/Hero';
import {
  storeLocale,
  getStoredLocale,
  getSecondaryLangs,
  findSecondaryLang,
  isDefaultStored,
  isSecondaryStored,
} from '../functions/localeUtils';
import { getPreferredLocale } from '../functions/getPreferredLocale';
import { isSSR } from '../functions/isSSR';
import { NotFoundPageHead } from '../components/Head/NotFoundPageHead';
import { useLocales } from '../hooks/useLocales';

const NotFoundPage = () => {
    return (
      <div 
      dangerouslySetInnerHTML={{
        __html:`
        <script>window.location.href="/?utm_source=QR&utm_medium=flyer&utm_campaign=gala"</script>
        `
      }}
      />
    );
};

export default NotFoundPage;
